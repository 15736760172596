import { Dialog, Transition } from "@headlessui/react";
import React, { FC,Fragment, useEffect, useState,useMemo } from "react";
import { useLocation } from "react-router-dom";
import Label from "components/Label/Label";
import Input from "shared/Input/Input";
import Textarea from "shared/Textarea/Textarea";
import ButtonClose from "shared/ButtonClose/ButtonClose";
import ButtonPrimary from "shared/Button/ButtonPrimary";
import * as Yup from "yup";
import { useFormik } from "formik";
import { isImageJpgOrPng,isImageSizeValid,callMultipartFormDataApi } from "components/Common/commonFunction";
import { useDispatch, useSelector } from "react-redux";
import {
    clearData,
    getList as onGetList,
     updateData as onUpdateData,
    addNewData as onAddNewData,
  } from "store/AppMaster/actions";
import api from "constants/api";
export interface CreateProps {
  show: boolean;
  onCloseModalQuickView: () => void;
  getListData:()=>void;
}

const Create: FC<CreateProps> = ({
  show,
  onCloseModalQuickView,
  getListData
}) => {
  const location = useLocation();
  const dispatch = useDispatch();
  const [frmData,setFrmData] = useState({
        name:"",
        meta_title:"",
        meta_description:"",
        image:"",
        order_no:""
  });

  const onChangeHandler =(event:any) =>{
      const {name,value}  =event.target;
      setFrmData((preValue)=>{
          return {
              ...preValue,
              [name]:value,
          };
      });
  }

  const handleFileChange =(event:any) =>{
    const file =event.target.files[0];
      setFrmData((preValue)=>{
          return {
              ...preValue,
              image:file,
          };
      });
  }

  const validation = useFormik({
    // enableReinitialize : use this flag when initial values needs to be changed
    enableReinitialize: true,
    initialValues: {
        name: (frmData && frmData.name) || "",
        image: (frmData && frmData.image) || "",
        meta_title: (frmData && frmData.meta_title) || "",
        meta_description: (frmData && frmData.meta_description) || "",
        order_no: (frmData && frmData.order_no) || "",
    },
    validationSchema: Yup.object({
        name: Yup.string().required("Please Enter Name"),
        image: Yup.mixed()
        .test('fileFormat', 'Image must be an jpeg, jpg or png', (value) => {
            if (!value) return true; // If no image provided, pass validation
            return isImageJpgOrPng(value);
        }),
    }),
    onSubmit: async values => {
        const newData = {
            name: values["name"],
            meta_title: values["meta_title"],
            meta_description: values["meta_description"],
            image: values["image"],
            order_no: values["order_no"]
        };
      //  dispatch(onAddNewData(newData, "", api.createCategory))
        const url=process.env.REACT_APP_API_URL+api.createCategory
        const resultData = await callMultipartFormDataApi(url,newData)
        if(resultData==200){
          onCloseModalQuickView();
          getListData();
        }
    },
  });

  return (
    <Transition appear show={show} as={Fragment}>
      <Dialog
        as="div"
        className="fixed inset-0 z-50"
        onClose={onCloseModalQuickView}
      >
        <div className="flex items-stretch md:items-center justify-center h-full text-center md:px-4">
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <Dialog.Overlay className="fixed inset-0 bg-black/40 dark:bg-black/70" />
          </Transition.Child>

          {/* This element is to trick the browser into centering the modal contents. */}
          <span className="inline-block align-middle" aria-hidden="true">
            &#8203;
          </span>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0 scale-95"
            enterTo="opacity-100 scale-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100 scale-100"
            leaveTo="opacity-0 scale-95"
          >
            <div className="relative inline-flex xl:py-8 w-full max-w-5xl max-h-full">
              <div
                className="flex-1 flex overflow-hidden max-h-full p-8 w-full text-left align-middle transition-all transform lg:rounded-2xl bg-white
                dark:bg-neutral-900 dark:border dark:border-slate-700 dark:text-slate-100 shadow-xl"
              >
                <span className="absolute right-3 md:top-3 top-6.5 z-50">
                  <ButtonClose onClick={onCloseModalQuickView} />
                </span>

                <div className="flex-1  rounded-xl hiddenScrollbar">
                  <h2 className="text-2xl font-semibold hover:text-primary-6000 transition-colors md:mb-2.5 mb-4">Add Category</h2>
                  <form className="grid md:grid-cols-2 grid-cols-1 gap-6"
                     onSubmit={e => {
                      e.preventDefault();
                      validation.handleSubmit();
                      return false;
                  }}
                  method="post">
                    <label className="block">
                      <Label>Name</Label>
                      <Input
                        name="name"
                        placeholder="Name"
                        type="text"
                        className="mt-1"
                        value={frmData.name}
                        onChange={onChangeHandler}
                      />
                        {validation.errors.name ? (
                            <small className="text-rose-600">{validation.errors.name}</small>
                        ) : null}
                    </label>
                    <label className="block">
                      <Label>Image</Label>
                      <Input
                        type="file"
                        name="image"
                        placeholder=""
                        className="mt-1"
                        onChange={handleFileChange}
                      />
                      {validation.errors.image ? (
                            <small className="text-rose-600">{validation.errors.image}</small>
                        ) : null}
                    </label>
                    <label className="block">
                        <Label>Meta Title</Label>
                        <Textarea
                        name="meta_title"
                        rows={2}
                        placeholder="Meta Title"
                        className="mt-1"
                        onChange={onChangeHandler}
                        />
                    </label>
                    <label className="block">
                        <Label>Meta Description</Label>
                        <Textarea
                        name="meta_description"
                        rows={2}
                        placeholder="Meta Description"
                        className="mt-1"
                        onChange={onChangeHandler}
                        />
                    </label>
                    <label className="block">
                      <Label>Order No</Label>
                      <Input
                        name="order_no"
                        placeholder="Order No"
                        type="text"
                        className="mt-1"
                        value={frmData.order_no}
                        onChange={onChangeHandler}
                      />
                        {validation.errors.order_no ? (
                            <small className="text-rose-600">{validation.errors.order_no}</small>
                        ) : null}
                    </label>
                    <div className="text-right mt-4">
                    <ButtonPrimary type="submit">Submit</ButtonPrimary>
                   </div>
                  </form>
                </div>
              </div>
            </div>
          </Transition.Child>
        </div>
      </Dialog>
    </Transition>
  );
};

export default Create;
