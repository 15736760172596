import { Dialog, Transition } from "@headlessui/react";
import React, { FC,Fragment, useEffect, useState,useMemo } from "react";
import { useLocation } from "react-router-dom";
import Label from "components/Label/Label";
import Input from "shared/Input/Input";
import ButtonClose from "shared/ButtonClose/ButtonClose";
import ButtonPrimary from "shared/Button/ButtonPrimary";
import * as Yup from "yup";
import Select from 'react-select'
import { useFormik } from "formik";
import { isImageJpgOrPng, isImageSizeValid, callMultipartFormDataApi, isSheet, isImageJpgOrPngOrPdf,presignedUrl,uploadFileToS3 } from "components/Common/commonFunction";
import { useDispatch, useSelector } from "react-redux";
import {
    clearData,
    getList as onGetList,
     updateData as onUpdateData,
    addNewData as onAddNewData,
  } from "store/AppMaster/actions";
import api from "constants/api";
import Loader from "containers/Admin/Loader";
export interface CreateProps {
  show: boolean;
  onCloseModalQuickView: () => void;
  optionCategory:any;
  id:any;
  SubCat:any;
  getCategoryDetails: () => void;
}


const SubCategory: FC<CreateProps> = ({
  show,
  onCloseModalQuickView,
  optionCategory,
  id,
  SubCat,
  getCategoryDetails
}) => {
  const location = useLocation();
  const selected =SubCat.map((subject: any) => subject.value);
  const dispatch = useDispatch();
  const [loderImage, setLoader] = useState(false);
  const [is_added, setAdded] = useState(false);
  const [selectedData, setSelectedData] = useState([]);
  const [frmData,setFrmData] = useState({
        categoryId:id,
        subCategoryIds:selected
  });

  const onChangeSelect = (event: any) => {
      const selectedValues = event.map((subject: any) => subject.value);
      setFrmData((preValue) => {
          return {
              ...preValue,
              "subCategoryIds": selectedValues
          };
      });
      setSelectedData(event)
      setAdded(true);
  };

  const validation = useFormik({
    // enableReinitialize : use this flag when initial values needs to be changed
    enableReinitialize: true,
    initialValues: {
      categoryId: (frmData && frmData.categoryId) || "",
      subCategoryIds:(frmData && frmData.subCategoryIds) ||  []
    },
    validationSchema: Yup.object({
      subCategoryIds: Yup.mixed().required('Price sheet is required')
    }),
    onSubmit: async (values: any) => {
      const newData = {
        categoryId: id,
        subCategoryIds:values["subCategoryIds"]
      };
      dispatch(onAddNewData(newData, "", api.addSubCategory))
      onCloseModalQuickView()
      setTimeout(() => {
        getCategoryDetails()
      }, 1000);
    },
  });

  return (
    <Transition appear show={show} as={Fragment}>
      <Dialog
        as="div"
        className="fixed inset-0 z-50"
        onClose={onCloseModalQuickView}
      >
        <Loader
                loderImage={loderImage}
            />
        <div className="flex items-stretch md:items-center justify-center h-full text-center md:px-4">
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <Dialog.Overlay className="fixed inset-0 bg-black/40 dark:bg-black/70" />
          </Transition.Child>

          {/* This element is to trick the browser into centering the modal contents. */}
          <span className="inline-block align-middle" aria-hidden="true">
            &#8203;
          </span>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0 scale-95"
            enterTo="opacity-100 scale-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100 scale-100"
            leaveTo="opacity-0 scale-95"
          >
            <div className="relative inline-flex xl:py-8 w-full max-w-5xl h-auto">
              <div
                className="flex-1 flex max-h-full p-8 w-full text-left align-middle transition-all transform lg:rounded-2xl bg-white
                dark:bg-neutral-900 dark:border dark:border-slate-700 dark:text-slate-100 shadow-xl"
              >
                <span className="absolute right-3 top-3 z-50">
                  <ButtonClose onClick={onCloseModalQuickView} />
                </span>

                <div className="flex-1  rounded-xl hiddenScrollbar">
                  <h2 className="text-2xl font-semibold hover:text-primary-6000 transition-colors mb-2.5">Add Sub Category</h2>
                  <form onSubmit={e => {
                    e.preventDefault();
                    validation.handleSubmit();
                    return false;
                  }}>
                    <div>
                      <Label>Sub Category</Label>
                      <Select
                        name="subCategoryIds"
                        options={optionCategory}
                        isMulti={true}
                        onChange={(event:any) => onChangeSelect(event)}
                        className="mt-1"
                        value={(is_added)? selectedData : SubCat}
                      />
                      {validation.errors.subCategoryIds ? (
                        // @ts-ignore
                        <small className="text-rose-600">{validation.errors.subCategoryIds}</small>
                      ) : null}
                    </div>
                    <ButtonPrimary className="mt-3 mt-md-0 !block ml-auto" type="submit">
                      <span>Update</span>
                    </ButtonPrimary>
                  </form>
                </div>
              </div>
            </div>
          </Transition.Child>
        </div>
      </Dialog>
    </Transition>
  );
};

export default SubCategory;
